







































































// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import baseUserContextPage from "@/pages/baseUserContextPage";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import _ from "lodash";
import { facetModel } from "@/models/facetModel";

@Component({})
export default class categoriesPage extends baseUserContextPage {
	categories: categoryModel[] = [];
	categoriesFacet: facetModel = null;
	isLoading: boolean = false;

	@Watch("$route")
	onRouteChange(to, from) {}

	get categoryId(): number {
		return this.$route && this.$route.params && this.$route.params.id ? Number.parseInt(this.$route.params.id) : null;
	}
	get categoriesSelected(): categoryModel[] {
		var instance = this;
		var c = instance.currentCategory;
		return c == null ? instance.categories || [] : c.subCategories || [];
	}
	get categoriesSorted(): categoryModel[] {
		var instance = this;
		var cat = [];
		if (instance.categoriesSelected != null) {
			cat = instance.categoriesSelected.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
		}
		return cat;
	}
	getSelectedCategory(categories: categoryModel[]): categoryModel {
		var instance = this;
		if (instance.categoryId == null) return null;
		if (categories == null || categories.length == 0) return null;
		else {
			for (var i = 0; i < categories.length; i++) {
				let c = categories[i];
				if (c.id == instance.categoryId) return c;
				c = instance.getSelectedCategory(c.subCategories);
				if (c != null) return c;
			}
			return null;
		}
	}
	get currentCategory(): categoryModel {
		var instance = this;
		if (instance.categoryId == null) return null;

		var c = instance.getSelectedCategory(instance.categories);
		return c;
	}
	get title(): string {
		var self = this;
		if (self.currentCategory == null) return "Catalogo";
		else {
			var l = self.currentCategory.locales["it-IT"];
			return l == null ? "" : l["name"];
		}
	}
	get description(): string {
		var self = this;
		if (self.currentCategory == null) return "";
		else {
			var l = self.currentCategory.locales["it-IT"];
			return l == null ? "" : l["description"] == "<p>&nbsp;</p>" ? "" : l["description"];
		}
	}
	getPropery(category: categoryModel, property: string) {
		if (category == null || category.locales == null) return "";
		var l = category.locales["it-IT"];
		return l == null ? "" : l[property];
	}
	onSelectBreadcrumb(c: categoryModel) {
		var instance = this;
		if (c == null) instance.$router.push({ path: `/categories` });
		else if (c.subCategories == null || c.subCategories.length == 0) instance.$router.push({ path: `/catalog/${c.id}` });
		else instance.$router.push({ path: `/categories/${c.id}` });
	}
	onSelectCategorySelector(c: categoryModel) {
		var instance = this;
		if (c == null) instance.$router.push({ path: `/categories` });
		else if (c.subCategories == null || c.subCategories.length == 0 || c.subCategories.every(s => s.subCategories == null || s.subCategories.length == 0))
			instance.$router.push({ path: `/catalog/${c.id}` });
		else instance.$router.push({ path: `/categories/${c.id}` });
	}

	created() {
		var instance = this;
		domainService.getCategories().then(res => (this.categories = res == null ? [] : res));
		domainService.getCategoriesFacet().then(res => (this.categoriesFacet = res == null ? new facetModel() : res));
	}
	mounted() {
		var instance = this;
	}
	get categoriesFigliOFoglie(): categoryModel[] {
		var instance = this;
		if (instance.categoryId == null) return instance.categories || [];

		var categories = instance.getCategoriesFigliOFoglie(instance.categories);
		return categories == null ? instance.categories || [] : categories;
	}
	getCategoriesFigliOFoglie(categories: categoryModel[]): categoryModel[] {
		var instance = this;
		if (instance.categoryId == null) return null;
		if (categories == null || categories.length == 0) return null;
		else {
			for (var i = 0; i < categories.length; i++) {
				let c = categories[i];
				if (c.id == instance.categoryId) {
					return (c.subCategories || []).length > 0 ? c.subCategories : categories;
				}
				let cats = instance.getCategoriesFigliOFoglie(c.subCategories);
				if (cats != null) return cats;
			}
			return null;
		}
	}
}
